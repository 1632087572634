  // 营销组件
  export const marketingIcon = [
    {
      name: 'coupon',
      icon: 'coupon',
      text: '优惠券',
      cursor: false,
      maximum: 50,
      num: 0,
      place: ['indexPage', 'pagesCustom', 'categoryPage', 'goodsDetail']
    },
    {
      name: 'payCoupon',
      icon: 'coupon',
      text: '付费优惠券',
      cursor: false,
      maximum: 10,
      num: 0,
      place: ['indexPage', 'pagesCustom', 'goodsDetail']
    },
    {
      name: 'zhekou',
      icon: 'alarm-clock',
      text: '限时折扣',
      cursor: false,
      maximum: 50,
      num: 0,
      developing: true,
      place: []
    },
    {
      name: 'miaosha',
      icon: 'lightning',
      text: '秒杀',
      cursor: false,
      maximum: 50,
      num: 0,
      place: ['indexPage', 'pagesCustom', 'categoryPage', 'goodsDetail']
    },
    {
      name: 'kanjia',
      icon: 'lightning',
      text: '砍价',
      cursor: false,
      maximum: 50,
      num: 0,
      place: ['indexPage', 'pagesCustom', 'categoryPage', 'goodsDetail']
    },
    {
      name: 'pintuan',
      icon: 'tuangou2',
      text: '拼团',
      cursor: false,
      maximum: 50,
      num: 0,
      place: ['indexPage', 'pagesCustom', 'categoryPage', 'goodsDetail']
    },
    // { name: 'kanjia', icon: 'kanjia', text: '砍价', cursor: false },
    // { name: 'jiaruhuiyuan', icon: 'level', text: '加入会员', cursor: false },
    {
      name: 'gexinghua',
      icon: 'theme',
      text: '个性化推荐',
      cursor: false,
      maximum: 1,
      num: 0,
      developing: true, // 开发中
      place: []
    }
  ]